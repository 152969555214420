<template>
    <div>
    <b-modal
      id="modal-cabang-tatakelola"
      ok-only
      centered
      size="xl"
      content-class="shadow"
      hide-footer
      hide-header
    >
    <b-container fluid class="mb-5">
      <b-row class="pt-5 mt-2 pr-0 pl-0">
        <b-col class="d-flex justify-content-between pr-0 pl-0">
          <b style="font-size:20px;">
            Detail - Cabang Ormas
          </b>
          <div>
            <b-button v-if="tabDetailCabang == 2 && detailCabang.file_sk_pengurus !== null" @click="newTab(fileURL + '/' + detailCabang.file_sk_pengurus)" variant="primary" size="sm" class="mr-3">
              <v-icon class="pr-3">mdi-clipboard</v-icon>
              Lihat SK Pengurus
            </b-button>
            <b-button :disabled="loadVerif" variant="primary" size="sm" class="mr-3" @click="verifikasi(detailCabang)">
            <v-icon class="pr-3">mdi-check-circle</v-icon>
            Sudah Lapor
          </b-button>
          <b-button variant="outline-secondary" style="background: #FFFFFF;" size="sm" class="mr-4" @click="$bvModal.hide('modal-cabang-tatakelola')">
              <v-icon class="pr-3">mdi-close</v-icon>
              Tutup
            </b-button>
            <!-- <button
              style="color:white;"
              class="btn font-weight-bolder font-size-sm bg-hitam"
              data-wizard-type="action-submit"
            >
              <i class="fas fa-file-pdf" style="color:white;"></i>Cetak PDF
            </button> -->
          </div>
        </b-col>
      </b-row>
    </b-container>
    {{detailCabang ? '' : ''}}
      <div v-if="detailCabang">
        <b-tabs
          pills
          card
          justified
          v-model="tabDetailCabang"
        >

          <b-tab
            active
            title="Profil"
            style="background-color:white;margin-top:0px;"
          >
            <b-row>
              <b-col cols="7">
                <b-row>
                  <b-col sm="3">
                    <p style="font-weight: 600">{{ "Nama Ormas" }}</p>
                  </b-col>:<b-col sm="6">{{ detailCabang.nama_cabang}}</b-col>
                </b-row>
                <b-row v-if="detailCabang.nama_singkatan">
                  <b-col sm="3">
                    <p style="font-weight: 600">{{ "Singkatan" }}</p>
                  </b-col>:<b-col sm="6">{{detailCabang.nama_singkatan}}</b-col>
                </b-row>
                <b-row>
                  <b-col sm="3">
                    <p style="font-weight: 600">
                      {{ "Status Sekretariat" }}
                    </p>
                  </b-col>:<b-col sm="6" v-if="detailCabang.masterLokasiSekretariat">{{ detailCabang.masterLokasiSekretariat.nama_lokasi }}</b-col>
                </b-row>
                <b-row>
                  <b-col sm="3">
                    <p style="font-weight: 600">
                      {{ "Alamat sekretariat" }}
                    </p>
                  </b-col>:<b-col sm="6">{{ detailCabang.alamat_sekretariat }}</b-col>
                </b-row>
                <b-row>
                  <b-col sm="3">
                    <p style="font-weight: 600">{{ "Kabupaten/Kota" }}</p>
                  </b-col>:<b-col sm="6" v-if="detailCabang.wilayahKabupaten">{{ detailCabang.wilayahKabupaten.nama }}</b-col>
                </b-row>
                <b-row>
                  <b-col sm="3">
                    <p style="font-weight: 600">{{ "Propinsi" }}</p>
                  </b-col>:<b-col sm="6">{{ detailCabang.wilayahProvinsi.nama }}</b-col>
                </b-row>
                <b-row>
                  <b-col sm="3">
                    <p style="font-weight: 600">{{ "Nomor Telepon" }}</p>
                  </b-col>:<b-col sm="6">{{ detailCabang.no_telp }}</b-col>
                </b-row>
                <b-row>
                  <b-col sm="3">
                    <p style="font-weight: 600">{{ "Nomor FAX" }}</p>
                  </b-col>:<b-col sm="6">{{ detailCabang.no_fax }}</b-col>
                </b-row>
                <b-row>
                  <b-col sm="3">
                    <p
                      style="font-weight: 600; magrin:0px"
                    >{{ "NPWP" }}</p>
                  </b-col>:<b-col sm="6">{{ detailCabang.npwp }}
                    <!-- <b-button size="sm" variant="success"><v-icon style="color:white;" small>mdi-eye</v-icon>Lihat File Scan</b-button> -->
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="5">
                <!-- <b-row>
                  <img
                    src="../../../../../assets/image/images/ormas_picture.png"
                    style="width: 80%; margin-bottom: 20px; margin-left: 8px; opacity:20%;"
                    alt="abstract"
                  />
                  <img
                    src="../../../../../assets/image/images/ormas_flag.png"
                    style="width: 80%; margin-bottom: 20px; margin-left: 8px; opacity:20%;"
                    alt="abstract"
                  />
                </b-row> -->
              </b-col>
            </b-row>
          </b-tab>
          <b-tab
            title="Info Pendirian"
            style="background-color:white;margin-top:0px;"
          >
            <b-row>
              <b-col cols="7">
                <!-- <b-row v-for="(item, i) in info" :key="i">
                  <b-col sm="4"
                    ><p style="font-weight: 600">{{ item.label }}</p></b-col
                  >:<b-col sm="6">{{ item.answer }} <b-button v-if="item.label == 'Nomor AKTA Pendirian' || item.label == 'Nomor Surat Pendaftaran' " size="sm" variant="success"><v-icon style="color:white;" small>mdi-eye</v-icon>Lihat File Scan</b-button></b-col>
                </b-row> -->
                <b-row>
                  <b-col sm="4">
                    <p style="font-weight: 600">Nama Notaris</p>
                  </b-col>:<b-col sm="6" v-if="detailCabang.infoPendirian">{{ detailCabang.infoPendirian.namaNotaris }} </b-col>
                </b-row>
                <b-row>
                  <b-col sm="4">
                    <p
                      style="font-weight: 600; margin:0px;"
                    >Nomor AKTA Pendirian</p>
                  </b-col>:<b-col sm="6" v-if="detailCabang.infoPendirian">{{ detailCabang.infoPendirian.nomorAkta }}
                    <b-button size="sm" v-if="detailCabang.infoPendirian.file_akta !== null && detailCabang.infoPendirian.file_akta !==''" @click="newTab(fileURL + '/' + detailCabang.infoPendirian.file_akta)" variant="success"><v-icon style="color:white;" small>mdi-eye</v-icon>Lihat File Scan</b-button>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm="4">
                    <p style="font-weight: 600">Tanggal AKTA Pendirian</p>
                  </b-col>:<b-col sm="6" v-if="detailCabang.infoPendirian">{{ detailCabang.infoPendirian.tanggalAkta | moment("DD - MM - YYYY")}} </b-col>
                </b-row>
                <b-row>
                  <b-col sm="4">
                    <p
                      style="font-weight: 600; margin:0px;"
                    >Nomor SK Susunan Pengurus</p>
                  </b-col>:<b-col sm="6" v-if="detailCabang.infoPendirian">{{ detailCabang.infoPendirian.nomorSuratPendaftaran }}
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm="4">
                    <p style="font-weight: 600">Tempat Pendirian</p>
                  </b-col>:<b-col sm="6" v-if="detailCabang.infoPendirian">{{ detailCabang.wilayahProvinsi ? detailCabang.wilayahProvinsi.nama + ',' : '' }} {{ detailCabang.wilayahKabupaten ? detailCabang.wilayahKabupaten.nama : '' }}  </b-col>
                </b-row>
                <b-row>
                  <b-col sm="4">
                    <p style="font-weight: 600">Tanggal Pendirian</p>
                  </b-col>:<b-col sm="6" v-if="detailCabang.infoPendirian">{{ detailCabang.infoPendirian.tanggalPendirian | moment("DD - MM - YYYY") }} </b-col>
                </b-row>
                <b-row v-if="detailCabang.infoPendirian && detailCabang.infoPendirian.masterKesbangpol">
                  <b-col sm="4">
                    <p style="font-weight: 600">Nama Kesbangpol</p>
                  </b-col>:<b-col sm="6" v-if="detailCabang.infoPendirian">{{ detailCabang.infoPendirian.masterKesbangpol.nama }} </b-col>
                </b-row>
                <b-row v-if="detailCabang.infoPendirian && detailCabang.infoPendirian.masterKesbangpol">
                  <b-col sm="4">
                    <p style="font-weight: 600">Email Kesbangpol</p>
                  </b-col>:<b-col sm="6" v-if="detailCabang.infoPendirian">{{ detailCabang.infoPendirian.masterKesbangpol.email }} </b-col>
                </b-row>
                <b-row v-if="detailCabang.infoPendirian && detailCabang.infoPendirian.masterKesbangpol">
                  <b-col sm="4">
                    <p style="font-weight: 600">Nomor HP Kesbangpol</p>
                  </b-col>:<b-col sm="6" v-if="detailCabang.infoPendirian">{{ detailCabang.infoPendirian.masterKesbangpol.nomorTelepon }} </b-col>
                </b-row>
              </b-col>
              <b-col cols="5">
                <!-- <b-row>
                  <img
                    src="../../../../../assets/image/images/ormas_picture.png"
                    style="width: 80%; margin-bottom: 20px; margin-left: 8px; opacity:20%;"
                    alt="abstract"
                  />
                  <img
                    src="../../../../../assets/image/images/ormas_flag.png"
                    style="width: 80%; margin-bottom: 20px; margin-left: 8px; opacity:20%;"
                    alt="abstract"
                  />
                </b-row> -->
              </b-col>
            </b-row>
          </b-tab>
          <b-tab
            title="Struktur Pengurus"
            style="background-color:white;margin-top:0px; padding-left:0px; padding-right:0px;"
          >
            <b-card-text class="pl-0 pr-0">
              <div class="card-body pt-0 pb-3 pl-0 pr-0">
                <div class="tab-content">
                  <!--begin::Table-->
                  <div class="table-responsive">
                    <table class="
                table
                table-head-custom
                table-vertical-center
                table-head-bg
                table-bordered
              ">
                      <thead>
                        <tr class="text-left">
                          <th style="min-width: 100px; padding-left:0px; padding-right: 0px;" class="text-capitalize">Foto</th>
                          <th style="min-width: 150px; padding-left:0px; padding-right: 0px;" class="text-capitalize">Nama</th>
                          <th style="min-width: 150px; padding-left:0px; padding-right: 0px;" class="text-capitalize">Masa Bakti</th>
                          <th style="width: 90px; padding-left:0px; padding-right: 0px;" class="text-capitalize">Jabatan</th>
                          <th style="width: 150px; padding-left:0px; padding-right: 0px;" class="text-capitalize">No. Identitas</th>
                          <th style="min-width: 100px; padding-left:0px; padding-right: 0px;" class="text-capitalize">Tanggal Lahir</th>
                          <th style="min-width: 100px; padding-left:0px; padding-right: 0px;" class="text-capitalize">No. HP</th>
                        </tr>
                      </thead>
                      <tbody style="background:#FAFAFA;">
                        <template v-for="(item, i) in detailCabang.pengurusOrmas">
                          <tr v-bind:key="i">
                            <td>
                             <img v-if="item.foto !== null && item.foto !== '' " :src="fileURL + '/' + item.foto"
                          style="width:64px; height:64px;"
                          alt="abstract"
                          @error="replaceByDefault"
                          />
                        <img
                          v-if="item.foto == null || item.foto == ''"
                          src="https://alppetro.co.id/dist/assets/images/default.jpg"
                          style="width:64px; height:64px;"                          alt="abstract"
                        /> 
                            </td>
                            <td>
                              <span class="
                          text-dark-75
                          font-weight-bolder
                          d-block
                          text-left
                          font-size-lg
                        ">{{ item.nama_pengurus }}</span>
                            </td>
                            <td>
                              <span class="
                          text-dark-75
                          font-weight-bolder
                          d-block
                          text-left
                          font-size-lg
                        ">{{item.periode_mulai | moment('DD-MM-YYYY')}} - {{ item.periode_selesai | moment('DD-MM-YYYY')}}</span>
                            </td>
                             <td>
                              <span class="
                          text-dark-75
                          font-weight-bolder
                          d-block
                          text-left
                          font-size-lg
                        ">{{ item.masterJabatan.nama_jabatan }}</span>
                            </td>
                            <td>
                              <span class="
                          text-dark-75
                          font-weight-bolder
                          d-block
                          text-left
                          font-size-lg
                        ">{{ item.identitas }}</span>
                            </td>
                            <td>
                              <span class="
                          text-dark-75
                          font-weight-bolder
                          d-block
                          text-left
                          font-size-lg
                        ">{{item.tanggal_lahir | moment("DD-MM-YYYY")}}</span>
                            </td>
                            <td>
                              <span class="
                          text-dark-75
                          font-weight-bolder
                          d-block
                          text-left
                          font-size-lg
                        ">{{ item.no_hp }}</span>
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </b-card-text>
          </b-tab>
          <b-tab
            title="Rekening"
            style="background-color:white;margin-top:0px;"
          >
            <div class="table-responsive">
              <table class="
                table
                table-head-custom
                table-vertical-center
                table-head-bg
                table-bordered
              ">
                <thead>
                  <tr class="text-left">
                    <th style="width: 20px" class="text-capitalize">No</th>
                    <th style="min-width: 50px" class="text-capitalize">Nomor Rekening</th>
                    <th style="min-width: 50px" class="text-capitalize">Nama Bank</th>
                    <th style="min-width: 50px" class="text-capitalize">Pemilik rekening</th>
                    <th style="min-width: 50px" class="text-capitalize">Jenis rekening</th>
                  </tr>
                </thead>
                <tbody style="background:#FAFAFA;">
                  <template v-for="(item, i) in detailCabang.rekeningOrmas">
                    <tr v-bind:key="i">
                      <td>
                        <span class="
                    text-dark-75
                    font-weight-bolder
                    d-block
                    text-left
                    font-size-lg
                  ">{{ i + 1 }}</span>
                      </td>
                      <td>
                        <span class="
                    text-dark-75
                    font-weight-bolder
                    d-block
                    text-left
                    font-size-lg
                  ">{{item.no_rekening}}</span>
                      </td>
                      <td>
                        <span class="
                    text-dark-75
                    font-weight-bolder
                    d-block
                    text-left
                    font-size-lg
                  ">{{item.nama_bank}}</span>
                      </td>
                      <td>
                        <span class="
                    text-dark-75
                    font-weight-bolder
                    d-block
                    text-left
                    font-size-lg
                  ">{{item.nama_pemilik_rekening}}</span>
                      </td>
                      <td>
                        <span v-if="item.masterJenisRekening" class="
                    text-dark-75
                    font-weight-bolder
                    d-block
                    text-left
                    font-size-lg
                  ">{{item.masterJenisRekening.nama}}</span>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </b-modal>      
      <b-row class="mt-5">
        <b-col class="d-flex align-items-center justify-content-between">
          <span style="font-size:16px; font-weight:bold;">List Cabang Ormas Yang Belum Melakukan Pelaporan Keberadaan</span>
        </b-col>
      </b-row>
        <div
            class="table-responsive mt-4"
            style="border-radius:15px;"
          >
            <table
              class="
            table
            table-head-custom
            table-vertical-center
            table-head-bg
            table-bordered
          "
              style="font-size:14px !important;"
            >
              <thead>
                <tr class="text-left">
                  <th style="width: 20px" class="text-capitalize">No.</th>
                  <th style="min-width: 50px" class="text-capitalize">Nama Ormas</th>
                  <th style="min-width: 50px" class="text-capitalize">Status Pelaporan Keberadaan</th>
                  <th style="min-width: 50px" class="text-capitalize">Bidang Kegiatan</th>
                  <th style="min-width: 50px" class="text-capitalize">Ciri Khusus</th>
                  <th style="min-width: 50px" class="text-capitalize">Aksi</th>
                </tr>
              </thead>
              <tbody v-if="listCabang.length == 0">
                  <template>
                      <tr>
                          <td colspan="6"> -- Tidak ada data -- </td>
                      </tr>
                  </template>
              </tbody>
              <tbody style="background: #FFFFFF;">
                <template v-for="(item, i) in paginateData">
                  <tr v-bind:key="i">
                    <td >
                      <span class="
                      text-dark-75
                      font-weight-bolder
                      d-block
                      font-size-lg
                    ">{{ i + 1}}</span>
                    </td>
                    <td class="text-left">
                      <span  class="
                      text-dark-75
                      font-weight-bolder
                      text-left
                      font-size-lg
                    "> {{ item.nama_cabang}}
                      </span>
                    </td>
                    <td class="text-left">
                      <span class="
                      text-dark-75
                      font-weight-bolder
                      d-block
                      text-left
                      font-size-lg
                    ">{{ item.status_cabang == 1 ? 'Sudah Lapor' : 'Belum Lapor'}}</span>
                    </td>
                    <td class="text-left">
                      <span class="
                      text-dark-75
                      font-weight-bolder
                      d-block
                      text-left
                      font-size-lg
                    ">{{ item.dataOrmas.bidangKegiatans[0].masterJenisOrmas.nama_jenis_ormas}}</span>
                    </td>
                    <td class="text-left">
                      <span class="
                      text-dark-75
                      text-left
                      font-weight-bolder
                      d-block
                      font-size-lg
                    ">{{ item.dataOrmas.masterCiriKhusus.nama_ciri_khusus}}</span>
                    </td>
                    <td>
                      <section>
                      <b-button
                        variant="success"
                        size="sm"
                        @click="getDetailCabang(item)"
                        style="width:60px;"
                      >
                      >>
                      </b-button>
                      </section>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
          <b-row>
            <b-col class="d-flex justify-content-end">
              <b-pagination
                v-model="currentPage"
                :total-rows="listCabang.length"
                :per-page="perPage"
                @change="fetchData()"
                aria-controls="my-table"
              ></b-pagination>
            </b-col>
          </b-row>
    </div>
</template>
<script>
import Swal from "sweetalert2";
export default {
  name: "Informasi",
  data() {
    return {
      det: null,
      ormasIdentify : null,
      perPage: 20,
      paginateData: [],
      pengurus: null,
      tabDetailRiwayat: 0,
      fileURL: process.env.VUE_APP_APPLICATION_FILE_URL,
      currentPage: 1,
      listCabang: [],
      detailCabang: {
        wilayahProvinsi: {nama:''},
        infoPendirian: {namaNotaris: ''}
      },
      tabDetailCabang: 0,
      loadVerif: false,
    };
  },
  mounted() {
    this.fetchData()
  },
  watch:{
    activeTab(/*to*/){
      
    }
  },
  methods: {
    replaceByDefault(e) {
      e.target.src = "/assets/images/default-thumbnail.jpg"
    },
    newTab(url){
      window.open(url);
    },
    fetchData(){
      this.$store
        .dispatch("getListCabangLapor")
        // .dispatch("getStructureOrganization", 4)
        .then((res) => {
          for(var i = 0; i < res.data.data.length; i++){
            if(res.data.data[i].status_cabang != 1) {
              this.listCabang.push(res.data.data[i])
            }
            this.paginate()
          }
          // this.listCabang = res.data.data
        })
    },
    verifikasi(item){
      this.loadVerif = true
      this.$store
      .dispatch("verifikasiCabangLapor", [item.id, {status: 1}])
      .then(() => {
        Swal.fire({
          title: "Status Laporan berhasil diperbarui",
          text: '',
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        }).then(() => {
        })
        // setTimeout(() => {
          this.$bvModal.hide('modal-cabang-tatakelola')
          this.loadVerif = false  
        // }, 1000);
        this.listCabang = []
        this.paginateData = []
        this.fetchData()
      }).catch(() => {
        Swal.fire({
          title: "Status Laporan gagal diperbarui",
          text: 'Periksa jaringan internet anda atau tunggu beberapa saat lagi',
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        }).then(() => {
        })
        this.loadVerif = false  
      })
    },
    paginate() {
      // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
      this.paginateData = this.listCabang.slice((this.currentPage - 1) * this.perPage, this.currentPage * this.perPage);
    },
    getProfileInfo(id){
      this.$store
        .dispatch("getProfileOrganization", id)
        .then((res) => {
          this.ormasIdentify = res.data
        })
    },
    getStructureOrganization(id){
      this.$store
        .dispatch("getStructureOrganization", id)
        // .dispatch("getStructureOrganization", 4)
        .then((res) => {
          this.pengurus = res.data.pengurusInti
        })
    },
    getDetailCabang(item){
      this.$store
      .dispatch("getDataDetailCabangLapor", item.id)
      .then((res) => {
        this.detailCabang = res.data.data
        if(res.data.data) this.$bvModal.show('modal-cabang-tatakelola')
      })
    }
  },
  computed: {
    
  }
};
</script>
<style>
  #modal-cabang-tatakelola___BV_modal_body_ {
    padding-left:0px !important;
    padding-right:0px !important;
    padding-top:0px !important;
    padding-bottom:2rem;
  }
</style>